import video from './assets/fantasy.mp4';
import logo_bw from './assets/logo_bw.png';
import logo_cl from './assets/logo_cl.png';
import profile_pic from './assets/sh.jpg';
import chain_gr from './assets/chain-gr-20.png';
import giticon from './assets/giticon.png';
import likedin from './assets/likedin.png';
import menu from './assets/hambuger.png'
import x from './assets/x.png'
import sass from './assets/pr.png';
import javascript from './assets/ae.png';
import react from './assets/ps.png';
import nodeexpress from './assets/ai.png';
import mongodb from './assets/xd.png';
import arrow from './assets/arrow.png';


// AWARDS
import communication from './assets/communication.jpg';
import ansan from './assets/ansan.jpg';


import trippo from './assets/trippo.jpg';
import minimalism from './assets/minimalism.jpg';
import trapped from './assets/trapped.jpg';
import eleven from './assets/eleven.jpg';

// VISUAL GRAPHIC
import nmbc from './assets/visual/nmbc.jpg';
import alltogether from './assets/visual/alltogether.jpg';
import grammysocial from './assets/visual/grammysocial.jpg';
import aquafree from './assets/visual/aquafree.jpg';
import sunginkyu from './assets/visual/sunginkyu.jpg';
import kimpo from './assets/visual/kimpo.jpg';

import Typed from "react-typed";
import './App.scss';
import { useState } from 'react';
// COMPONENTS
function Navigation(){
  const [show, setShow] = useState(menu)
  const [expand, setExpand] = useState('')
  const showing = () => {
    setShow(x)
    setExpand('expand')
  }
  const closing = () => {
    setShow(menu)
    setExpand('')
  }
  return <nav className='navigation'>
  <ul>
    <li className='title'>
      <a href="/" className='title'>
        <img src={logo_cl} className="logoImg"></img><span>Sunghyun Park</span>
      </a>
      <img src={show}
      onClick={()=>{(show == menu)?showing():closing()}}
      className="hambuger"
      alt="menu icon" />
    </li>
    <li className={expand}><a href="#about">About</a></li>
    <li className={expand}><a href="#technologies">Technologies</a></li>
    <li className={expand}><a href="#awards">Awards</a></li>
    <li className={expand}><a href="#projects">Projects</a></li>
    {/* <li className={expand}><a href="https://drive.google.com/file/d/168_RW-CckBmycmEa-B1_b5bUnXnaBwZm/view?usp=sharing">Resum&#233;</a></li> */}
  </ul>
</nav>
} 
function Topsection(){
  return <div className='topSection'>
  <div className='videoWrapper'>
    <video src={video} autoPlay loop playsInline muted={true} className='videoTop'></video>
  </div>
  <div className='topText'>
    <img src={logo_cl}></img>
    <h1>Sunghyun Park</h1>
    <h3>Multimedia Specialist</h3>
    {/* https://gurtn.tistory.com/168 */}
    <a className='button' href="mailto:olivesunghyun@gmail.com">
      <span>Contact &#x3e;</span>
    </a>
  </div>
</div>
}
function About(){
  return <div id='about'>
  <article className='aboutArea'>
    <img src={profile_pic}></img>
    <div className='aboutText'>
      <h2>Hello! I am Sunghyun Park,<br/> also called Collin!</h2>
      <p>Dedicated and highly skilled <b>Multimedia Specialist</b> with a proven track record of creating engaging and visually stunning multimedia content.</p>
      <p>Experienced in creating multimedia content such as video, animation, graphic design, web design, and web development. I am committed to finding clients' pain points and providing optimum solutions.</p>
      <p>As a strong communicator, I am always seeking opportunities to contribute creativity, technical expertise, and multimedia proficiency to a dynamic organization in pursuit of innovative multimedia solutions.</p>
    </div>
  </article>
</div>
}
function Technologies(){
  return <div id="technologies">
  <div className='techWrapper'>
    <h2 className='h2Title'>Technologies</h2>
    <p>that I've been mainly working with recently</p>
    <ul className='techStack'>
      <li><img className="techIcons" src={sass}></img></li>
      <li><img className="techIcons" src={javascript}></img></li>
      <li><img className="techIcons" src={react}></img></li>
      <li><img className="techIcons" src={nodeexpress}></img></li>
      <li><img className="techIcons" src={mongodb}></img></li>
    </ul>
  </div>
  <section>
    <article>
      <h3>Video & Motion</h3>
      <div className='techList'>
        <span>#After Effect</span><span>#Premiere Pro</span><span>#Cinema4D</span>
      </div>
    </article>
    <article>
      <h3>Visual Graphic</h3>
      <div className='techList'>
        <span>#Photoshop</span><span>#Illustrator</span><span>#XD</span><span>#Indesign</span>
      </div>
    </article>
    <article>
      <h3>Web Development</h3>
      <div className='techList'>
        <span>#JavaScript</span><span>#SASS</span><span>#React</span><span>#Node</span>
      </div>
    </article>
  </section>
</div>
}
function Contact(){
  return <div id="contact">
  <article>
    <h2 className='h2Title contactTitle'>Contact</h2>
    <p>Please contact me at <a href="mailto:oliveguy85@gmail.com"><b>olivesunghyun@gmail.com</b></a></p>
    <p>and call me at +1 403-472-2948</p>
    {/* <div className='links'>
      <a href="https://github.com/oliveguy"><img src={giticon}/></a>
      <a href="https://www.linkedin.com/in/sunghyun-park-a29438243"><img src={likedin}/></a>
    </div> */}
  </article>
</div>
}
function Footer(){
  return <footer>
  <ul>
    <li><b>SUNGHYUN PARK</b></li>
    <li className='copyright'>Copyright © 2023, Sunghyun Park. All Rights Reserved.</li>
    {/* <li>
      <a href="https://github.com/oliveguy"><img src={giticon}/></a>
      <a href="https://www.linkedin.com/in/sunghyun-park-a29438243"><img src={likedin}/></a>
    </li> */}
  </ul>
</footer>
}
function Detail(props){
  return (
    (props.detail == "")?"":
    <div className="detail">
      <img src={`/img/${props.detail.img}.jpg`} alt={props.detail.title} />
      <div className='detailText'>
        <h3>{props.detail.title}</h3>
        <p>{props.detail.desc}</p>
        <br />
        <h4>Feature</h4>
        <p>{props.detail.feature}</p>
        <br />
        <a href={props.detail.url}>Go to {props.detail.title}</a>
      </div>
    </div>
  )
}
// THE APP
function App() {
  const [detail, Setdetail] = useState('');
  const videoItems = [
    {
      id:0,
      title: 'Trippo',
      poster:'',
      brief: 'Advertising animation for travel app',
      format: 'MP4',
      contribution:['Personal project', 'Script', 'StoryBoard', 'Music', 'Graphics', 'Animation'],
      process:['Planning', 'Script', 'StoryBoard', 'Prepare graphic elements and sound', 'combine all together in After Effects'],
      software:['AfterEffects','Illustrator', 'Photoshop'],
      storyboard:'',
      videoLink:''
    }
  ]
  const webItems = [
    {
      id:0,
      title:'Convertrix',
      brief:'A versatile unit converter',
      desc:'Convert various units of measurement quickly and accurately',
      img:'convertrix',
      tech:['React','SASS'],
      url:'http://devsunghyun.com/convertrix-deploy/',
      git:'https://github.com/oliveguy/Convertrix',
      feature:'Convert various units such as length, mass, area, and volume built with React.js'
    },
    {
      id:1,
      title:'PetLink',
      brief:'Pet dating web-application',
      desc:'Mobile-first designed web-app for pet dating',
      img:'petlink',
      tech:['Express.js','MongoDB','React','SASS'],
      url:'https://formal-station-384513.uw.r.appspot.com/',
      git:'https://github.com/oliveguy/PetLink',
      feature:'Login / Sigup and live chatting functionalities with mobile first design'
    },
    {
      id:2,
      title:'TACA',
      brief:'WordPress website for Tick Awareness Canada Association',
      desc:'Tick Awareness Canada Association website which is Canadian non-profit dedicated to universal, proactive tick safety and education',
      img:'TACA',
      tech:['WordPress','PHP','SASS'],
      url:'https://tickawarenesscanada.net/',
      git:'https://github.com/oliveguy/TACA',
      feature:'Donation fuction / eCommerce / Responsice design'
    },
    {
      id:3,
      title:'TalkieTalk',
      brief:'Real time chating app',
      desc:'Chating app with signup & login function',
      img:'talkitalk',
      tech:['Express.js','MongoDB','React','SASS'],
      url:'https://talkietalk-front.uw.r.appspot.com/',
      git:'https://github.com/oliveguy/live-chat-app',
      feature:'User can talk with other users in real-time with login and sigup function'
    },
    {
      id:4,
      title:'Rest Country API',
      brief:'Interactive coutry Info. app',
      desc:'Interactive coutry app to view each information using APIs',
      img:'restapicountry',
      tech:['SASS','React','API'],
      url:'http://devsunghyun.com/country-api-app-deploy/',
      git:'https://github.com/oliveguy/REST-Countries-API',
      feature: 'Interative web application which user can choose the country to view detailed information as well as sorting function'
    },
    {
      id:5,
      title:'Dev.Talk',
      brief:'Interactive Comment App',
      desc:'Blog comment app to add post, reply, andlike',
      img:'devtalk',
      tech:['SASS','React'],
      url:'http://devsunghyun.com/comment_app_deploy/',
      git:'https://github.com/oliveguy/interative-comment-app',
      feature: 'Posting and Commenting web application built with React.js and Restfull APIs.'
    },
    {
      id:6,
      title:'Tremolo',
      brief:'e-learning website',
      desc:'e-learning website for very beginners to learn how to play the guitar',
      img:'tremolo',
      tech:['SASS','JS','Express.js','MongoDB'],
      url:'https://tremolo-382318.wl.r.appspot.com/',
      git:'https://github.com/oliveguy/tremolo',
      feature: 'Login / Signup with bcrypt library & user information can be checked in user account panel. Checking course progress in account page and course pages / Auto pause function in video to secure practice time'
    },
    {
      id:7,
      title:'Fish Creek Prov. Park',
      brief:'Interactive map',
      desc:'Interactive map for Fish Creek Park in Calgary, AB',
      img:'fishcreek',
      tech:['JS','PHP','MySQL'],
      url:'http://dev.saitnewmedia.ca/~spark/fishcreek/',
      git:'https://github.com/oliveguy/fish-creek',
      feature: 'The map provides you with ample information on interesting points of Fish Creek Park by mythical creatures. Users can see each intriguing points of interest in Canada\'s second largest urban park. and post thier reviews without refreshing the page (AJAX).'
    },
    {
      id:8,
      title:'Coco Bakery',
      brief:'e-Commerce',
      desc:'e-Commerce website for selling bread and sweat treats to customers',
      img:'coco',
      tech:['JS','PHP','MySQL'],
      url:'http://dev.saitnewmedia.ca/~spark/mmda225/final/',
      git:'https://github.com/oliveguy/e-commerce',
      feature:'Signup and login functions / Detailed information pages depending on item / Addtional Cart functions such as removing items and selecting the quantity.'
    },
    {
      id:9,
      title:'Infinity & Beyond',
      brief:'2022 NASA International Space Apps Challenge',
      desc:'Website for displaying current aurora status',
      img:'iab',
      tech:['JS','PHP','MySQL'],
      url:'https://www.infinityandbeyond.club/',
      git:'https://github.com/hejkeikei/infinity-and-beyond',
      feature: 'User can see aurora forecast and adjust intensity to find aurora status'
    },
    {
      id:10,
      title:'Porfolio',
      brief:'My portfolio website',
      desc:'Portfolio website to introduce Sunghyun',
      img:'portfolio',
      tech:['SASS','JS','REACT'],
      url:'https://devsunghyun.com/',
      git:'https://github.com/oliveguy/portfolio',
      feature:'Easy contact and simple UI & UX'
    },
    {
      id:11,
      title:'NMPD Promotion',
      brief:'NMPD Program website',
      desc:'This website is to introduce NMPD',
      img:'nmpd',
      tech:['HTML','Pure CSS','JS'],
      url:'http://dev.saitnewmedia.ca/~spark/NMPD_promotion/',
      git:'https://github.com/oliveguy/portfolio',
      feature:'Intro website for NMPD(New Media Production and Design) with interactive videos'
    }
  ]
  const uiuxItems = [
    {
      id:0,
      title:'Grammy\'s',
      img:'grammy',
      url:'https://xd.adobe.com/view/5089acd2-d3b5-4ccd-b26a-bbcb584f5df6-05da/grid'
    },
    {
      id:1,
      title:'Tipplo',
      img:'tipplo',
      url:'https://devcollin.com/tipplo/'
    }
  ]
  const [transform, SetTransform] = useState(0);
  return (
    <div className="App">
      <Navigation></Navigation>
      <Topsection></Topsection>
      <About></About>
      <Technologies></Technologies>
      <section className='awards' id="awards">
      <h2 className='h2Title'>Award & Prize</h2>
      <p>I've honorably won</p>
      <div className='awardList'>
        <div>
          <img src={communication} alt="communication"/>
          <h4>Special Prize: C.I Branding</h4>
          <p>at 2008 International Communication Design Competition</p>
          <p>Awared by Communcation Design Association of Korea</p>
          <p>June 20, 2008</p>
        </div>
        <div>
          <img src={ansan} alt="ansan" width="500px" height="auto"/>
          <h4>Excellence: Advertising Graphic</h4>
          <p>at 2007 7th. Industrial Design Competition</p>
          <p>Awared by City of Ansan, Kyoung-gi, South Korea</p>
          <p>September 25, 2008</p>
        </div>
      </div>
      </section>
      <div id='projects'>
        <div className='projectText'>
          <h2 className='h2Title'>Projects</h2>
          <p>which I participated in and contributed to</p>
        </div>
        

        <section className='visual'>
          <h3 className='projectSubTitle visualHeading'>Visual Graphics</h3>
          <ul className='visualul'>
            <li className='visualList'>
              <a href="https://drive.google.com/file/d/1tIbX7tI7tB4o0ki6InaSea5VxA4DMSdx/view?usp=sharing">
                <img src={nmbc} alt="nmbc" />
              </a>
              <span className='visualItems'><b>#ADVERTISING</b></span>
              <h4>2023 National men's basketball championship</h4>
              <p>Advertising campaign graphics for sports event</p>
              <a href="https://drive.google.com/file/d/1tIbX7tI7tB4o0ki6InaSea5VxA4DMSdx/view?usp=sharing" className='viewMore'>View More</a>
            </li>
            <li className='visualList'>
              <a href="https://drive.google.com/file/d/1brBGwG2FBIzbxXZvVx47pr1M7PFLm0Wv/view?usp=sharing">
                <img src={alltogether} alt="alltogether" />
              </a>
              <span className='visualItems'><b>#BRANDING</b></span>
              <h4>Woori-Together Daycare Center for seniors</h4>
              <p>Created C.I. and branding: compnay-wide applications</p>
              <a href="https://drive.google.com/file/d/1brBGwG2FBIzbxXZvVx47pr1M7PFLm0Wv/view?usp=sharing" className='viewMore'>View More</a>
            </li>
            <li className='visualList'>
              <a href="https://drive.google.com/file/d/1ZFyTNvNA0bNmCGjZQoPDItT0PIQv_amb/view?usp=sharing">
                <img src={grammysocial} alt="grammy" />
              </a>
              <span className='visualItems'><b>#SOCIAL MEDIA</b></span>
              <h4>Grammy's Bakery</h4>
              <p>Promotional graphic for social media channel</p>
              <a href="https://drive.google.com/file/d/1ZFyTNvNA0bNmCGjZQoPDItT0PIQv_amb/view?usp=sharing" className='viewMore'>View More</a>
            </li>
            <li className='visualList'>
              <a href="https://drive.google.com/file/d/1_DkUrFEfucXD3jRQrQT05w85CVSv9B4T/view?usp=sharing">
                <img src={aquafree} alt="aquafree" />
              </a>
              <span className='visualItems'><b>#BRANDING</b></span>
              <h4>Aquafree</h4>
              <p>Created brand identity and its applications</p>
              <a href="https://drive.google.com/file/d/1_DkUrFEfucXD3jRQrQT05w85CVSv9B4T/view?usp=sharing" className='viewMore'>View More</a>
            </li>
            <li className='visualList'>
              <a href="https://drive.google.com/file/d/1sZDhni7IxgSMtjqpeduy9yC8yr6BdwJI/view?usp=sharing">
                <img src={sunginkyu} alt="sunginkyu" />
              </a>
              <span className='visualItems'><b>#LEAFLET</b></span>
              <h4>Seoung In-Kyu, English teacher</h4>
              <p>Photography and leaflet</p>
              <a href="https://drive.google.com/file/d/1sZDhni7IxgSMtjqpeduy9yC8yr6BdwJI/view?usp=sharing" className='viewMore'>View More</a>
            </li>
            <li className='visualList'>
              <a href="https://drive.google.com/file/d/1GiKaZD5orVuWTSK1HjRMP57Wl3IY15uw/view?usp=sharing">
                <img src={kimpo} alt="kimpo" />
              </a>
              <span className='visualItems'><b>#BOOK DESIGN</b><b>#EDITTING</b></span>
              <h4>Kimpo College 8th Graduation Book</h4>
              <p>Editor of the book and entire layouts</p>
              <a href="https://drive.google.com/file/d/1GiKaZD5orVuWTSK1HjRMP57Wl3IY15uw/view?usp=sharing" className='viewMore'>View More</a>
            </li>
          </ul>
        </section>
        <section className='uiux'>
        <h3 className='projectSubTitle'>UI/UX</h3>
          <ul>
            {uiuxItems.map((uiuxItem,i)=>{
              return <li key={i}>
              <a href={uiuxItem.url} target='_blank'><img src={require(`./assets/${uiuxItem.img}.jpg`)} className="uiuxview"/></a>
              {/* <img src={require(`./assets/blank.jpg`)}/> */}
              <a href={uiuxItem.url} target='_blank'>Learn more</a>
              {/* <a href={uiuxItem.url} target='_blank'>Learn more<img src={chain_gr}></img></a> */}
              </li>
              })
            }

          </ul>
        </section>
        <section className='video'>
          <h3 className='projectSubTitle'>Video & Motion Graphics</h3>
          <ul className='videoList'>
            {/* TRIPPO */}
            <li>
              <h3>Trippo</h3>
              <p>Advertising animation for travel app</p>
              <video
                src={require(`./assets/Trippo.mp4`)}
                width="80%"
                height="auto"
                controls
                id="videoplay"
                poster={trippo}>
              </video>
              <ul>
                <li><b>Contributions</b>Personal project(100%), Script, StoryBoard, Music, Graphics, Animation</li>
                <li><b>Process</b>Planning, Script, StoryBoard, Prepare graphic elements and sound, combine all together in After Effects</li>
                <li><b>Softwares used in this video</b>
                  <ul className='software'>
                    <li><img className="techIcons" src={javascript}></img></li>
                    <li><img className="techIcons" src={react}></img></li>
                    <li><img className="techIcons" src={nodeexpress}></img></li>
                  </ul>
                </li>
                <li className='docuDown'>
                  <a href="https://drive.google.com/file/d/1Iuda9j0R-YZetiZWN5weJJzuD_yclfYN/view?usp=sharing" className='videoDown'>StoryBoard</a>
                  <a href="https://drive.google.com/file/d/1sTZHilPzjmyAoRKrWttDQUp2f8K-aNvP/view?usp=sharing" className='videoDown'>Reflection</a>
                </li>
              </ul>
            </li>
              <hr />
            {/* MINIMALISM */}
            <li>
              <h3>Minimalism</h3>
              <p>Design concept explainer animation</p>
              <video
                src={require(`./assets/Minimalism.mp4`)}
                width="80%"
                height="auto"
                controls
                id="videoplay"
                poster={minimalism}>
              </video>
              <ul>
                <li><b>Contributions</b>Personal project(100%), Script, StoryBoard, Music, Graphics, Animation</li>
                <li><b>Process</b>Planning, Script, StoryBoard, Prepare graphic elements and sound, combine all together in After Effects</li>
                <li><b>Softwares used in this video</b>
                  <ul className='software'>
                    <li><img className="techIcons" src={javascript}></img></li>
                    <li><img className="techIcons" src={react}></img></li>
                    <li><img className="techIcons" src={nodeexpress}></img></li>
                  </ul>
                </li>
                <li className='docuDown'>
                  <a href="https://drive.google.com/file/d/1DuaNVp9uOlOeN3rA_ekGh9O9Qn--wD73/view?usp=sharing" className='videoDown'>StoryBoard</a>
                </li>
              </ul>
            </li>
            <hr />
            {/* TRAPPED */}
            <li>
              <h3>Trapped</h3>
              <p>Advertising video including interviews with business owner</p>
              <video
                src={require(`./assets/Trapped.mp4`)}
                width="80%"
                height="auto"
                controls
                id="videoplay"
                poster={trapped}>
              </video>
              <ul>
                <li><b>Contributions</b>Filming, Lighting, Music, Editting, Location and Directing</li>
                <li><b>Process</b>Planning, StoryBoard, video shooting, editting, music</li>
                <li><b>Softwares used in this video</b>
                  <ul className='software'>
                    <li><img className="techIcons" src={sass}></img></li>
                    <li><img className="techIcons" src={react}></img></li>
                    <li><img className="techIcons" src={nodeexpress}></img></li>
                  </ul>
                </li>
                {/* <li className='docuDown'>
                  <a href="" className='videoDown'>StoryBoard</a>
                  <a href="" className='videoDown'>Reflection</a>
                </li> */}
              </ul>
            </li>
            <hr />
            {/* ELEVEN */}
            <li>
              <h3>Eleven</h3>
              <p>Promotion and introduction video for residential property</p>
              <video
                src={require(`./assets/Eleven.mp4`)}
                width="80%"
                height="auto"
                controls
                id="videoplay"
                poster={eleven}>
              </video>
              <ul>
                <li><b>Contributions</b>Filming, Lighting, Music, Editting, Location and Directing</li>
                <li><b>Process</b>Planning, StoryBoard, video shooting, editting, music</li>
                <li><b>Softwares used in this video</b>
                  <ul className='software'>
                    <li><img className="techIcons" src={sass}></img></li>
                    <li><img className="techIcons" src={react}></img></li>
                    <li><img className="techIcons" src={nodeexpress}></img></li>
                  </ul>
                </li>
                <li className='docuDown'>
                  <a href="https://drive.google.com/file/d/1Z10O_jw5FvQ6amLr-5OT6jYJjxhotoSU/view?usp=sharing" className='videoDown'>StoryBoard</a>
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <section className='webDev'>
          <h3 className='projectSubTitle'>Web Development</h3>
          <ul>
            {webItems.map((items,i)=>{
              return <li key={items.id} style={{transform: `translateX(${transform}px)`,transition:'.25s ease-in-out'}}>
                <h4>{items.title}</h4>
                <img
                  onClick={()=>{Setdetail(webItems[i])}}
                  src={require(`./assets/${items.img}.jpg`)}
                  className="webProjectImg"
                />
                <div className='webProjectText'>
                  <p>{items.brief}</p>
                  <p className='desc'>{items.desc}</p>
                  <p>{items.tech.map((techItem,i)=>{return <span key={i}>#{techItem}</span>})}</p>
                </div>
                <div className='projectLink'>
                  <a href={items.url} target='_blank'><img src={chain_gr}></img></a>
                  {/* <a href={items.git} target='_blank'><img src={giticon}></img></a> */}
                </div>
              </li>
            }
            )}
          {/* https://blog.naver.com/wormsbrother/222769963949 */}
          </ul>
          <div className='arrows'>
            <span><img src={arrow} onClick={()=>{(transform === 0)?SetTransform(-2240):SetTransform(transform+320)}} alt="arrow" /></span>
            <span><img src={arrow} onClick={()=>{(transform === -2240)?SetTransform(0):SetTransform(transform-320)}} alt="arrow" /></span>
          </div>
        </section>
        <Detail detail={detail}></Detail>

      </div>
      <Contact></Contact>
      <Footer></Footer>
      
    </div>
  );
}

export default App;
